import React from "react";

import mobile from "../assets/images/home_portrait.png";
import Features from "./Features";

const Hero = () => {
  return (
    <div>
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="fade-up">
          <div className="row justify-content-center">
            <div className="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-1 order-lg-1 d-flex flex-column justify-content-center">
            {/* <div className="section-title"> */}

              <h1 >KClub Technologies</h1>
              <p >
              Welcome to Kclub Technologies, your one-stop solution for all your financial needs. Our Fintech platform offers a wide range of loan and insurance products designed to meet your specific requirements. Our platform provides you with a quick and easy way to apply for loans and insurance online, with minimal paperwork and hassle-free processing                    </p>
              <div>
                <a href="#pricing" className="btn-get-started scrollto">
                  Get Started
                </a>
              {/* </div> */}
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 order-2 order-lg-2 hero-img p-sm-0"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <img src={mobile} style={{width:"70%", marginTop: "0%", marginBottom: "4%"}} className="img-fluid animated" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Features />
    </div>
  );
};

export default Hero;
