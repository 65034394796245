import React from "react";

export const TermsConditions = () => {
  return (
    <section id="" className="about">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 order-1 order-lg-2"
            data-aos="zoom-in"
            data-aos-delay="150"
          ></div>
          <div
            className="col-lg-12 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
          >
            <h3>Terms and Conditions</h3>

            <ol type="1">
              <li className="mt-2">
                By using KClub’s services (referred to collectively as
                “Services”), you understand and agree that KClub will treat your
                use of the Services as acceptance of these terms and conditions
                (“Terms”) from that point onwards.
              </li>
              <li className="mt-2">
                You understand and acknowledge that “KClub” and domain name
                www.kclub.me belongs to KClub Technologies Private Limited.
                KClub Technologies Private Limited owns and operates an online
                technology platform for the purpose of providing a platform for
                persons to register for obtaining loans from financial
                institutions in India. Registered Address: KClub (KClub
                Technologies Private Limited), 7TH Floor, Wing B-3, Flat 706,
                Oberoi Sprint CTS No 705 ETC, Off Link Road, Mumbai, Maharashtra
                400058.
              </li>
              <li className="mt-2">
                You acknowledge and agree that KClub owns all legal rights,
                titles and interests in and to the Services, including any
                intellectual property rights which subsist in the Services
                (whether those rights are registered or not, and wherever in the
                world those rights exist).
              </li>
              <li className="mt-2">
                You understand that you do not have the right to use any of
                KClub’s trade names, trademarks, service marks, logos, domain
                names, and other distinctive brand features. You do not have the
                right to remove, obscure, or alter any proprietary rights
                notices (including trademark and copyright notices), which may
                be affixed to or contained within the Services. You will not
                copy or transmit any of the Services.
              </li>
              <li className="mt-2">
                You further acknowledge that the Services may contain
                information which is designated confidential by KClub and that
                you shall not disclose such information without KClub’s prior
                written consent. You will not copy, create a derivative work
                from, modify, reverse engineer, reverse assemble or otherwise
                attempt to discover any source code, sell, assign, sub-license,
                grant a security interest in or otherwise transfer any right in
                the Services.
              </li>
              <li className="mt-2">
                You agree not to use the website for any purpose that is
                unlawful, illegal or forbidden by these Terms, or any local laws
                that might apply to you. KClub may, at its sole discretion, at
                any time and without advance notice or liability, suspend,
                terminate or restrict your access to all or any component of the
                website or the availability of the website itself.
              </li>
            </ol>
            <p className="mainHead">ELIGIBILITY</p>
            <ol type="1">
              <li className="mt-2">
                You hereby declare that you are over 18 years of age, of sound
                mind and a person resident in India as defined under Foreign
                Exchange Management Act, 1999.
              </li>
            </ol>
            <p className="mainHead">SERVICES</p>
            <ol type="1">
              <li className="mt-2">
                You hereby confirm to register with us to avail a loan/ credit
                from one or more lending partners using the Services provided by
                KClub.
              </li>
              <li className="mt-2">
                You hereby understand and acknowledge that the information
                requested from you is used for the various purposes as set out
                in our Privacy Policy{" "}
                <a href="https://kclub.me/privacy-policy/">
                  https://kclub.me/privacy-policy/
                </a>{" "}
                (“Privacy Policy”), including for the purposes of us / our
                partners complying with various laws, regulations and guidelines
                (including Know Your Customer (KYC) norms / Anti-Money
                Laundering (AML) standards / Combating of Financing of Terrorism
                (CFT) / Obligation of banks under the Prevention of Money
                Laundering Act, 2002) promulgated by the Government of India
                from time to time. You further acknowledge that if you do not
                provide the requested information to KClub, KClub or its
                partners will be unable to provide or continue its / their
                services to you.
              </li>
              <li className="mt-2">
                You confirm that all information given to KClub (whether in
                KClub’s online form or otherwise) is true, correct and not
                misleading. You agree to provide any additional documents to
                KClub or any of its partners as may be required from time to
                time.
              </li>
              <li className="mt-2">
                You acknowledge that KClub does not lend any money directly to
                you, but arranges the loan for you from one or more lending
                partners.
              </li>
              <li className="mt-2">
                The KClub Credit Limit provided to you is not a credit facility
                in itself but is our assessment of your eligibility based on
                various factors including policies of our lending partners, the
                market influences and our previous experiences.You hereby
                understand that when you apply for a loan, KClub or one or more
                of its lending partners, may check your records, amongst other
                things, with its/ their own records, and any information as
                available to KClub or the lending partners through any of its
                sources, including those at credit information companies (CICs);
                those at fraud prevention agencies (FPAs) for assessing your
                credit worthiness, verifying identity to prevent and detect
                crime and money laundering.
              </li>
              <li className="mt-2">
                You also agree that if you avail any loans through KClub, you
                will not (i) use the proceeds thereof for any purpose other than
                as declared to KClub or its lending partners, (ii) accept or
                attempt to receive the credit facility in the form of cash or
                any other means, (iii) use the Services in violation of
                applicable law, or (iv) circumvent or attempt to circumvent the
                provisions of any terms that apply to you or act in a manner
                that is fraudulent, malicious or otherwise prejudicial to
                KClub’s reputation direct or indirect interests in relation to
                the Services
              </li>
              <li className="mt-2">
                You acknowledge that the details of all loans availed by you on
                the KClub platform will be reported to the credit information
                bureaus as required under applicable law. Your lender also has
                the right, and obligation, to report your details to CICs in the
                event that you have delayed repaying your loan instalment.
              </li>
              <li className="mt-2">
                You acknowledge that interest rates may vary across different
                lenders, borrowers and loans. To understand the approach that
                may be followed by your lender in determining the interest rate,
                please visit the website of our lending partners.
              </li>
              <li className="mt-2">
                If your loan is eligible for interest cashback then you can
                receive such cashback by paying your complete EMI on or before
                the due date. The cashback amount will be refunded within 5
                working days from the date of repayment of your last EMI.
              </li>
            </ol>
            <p className="mainHead">CUSTOMER COMMUNICATIONS</p>
            <ol type="1">
              <li className="mt-2">
                Accepting these Terms, implies your express consent to be
                contacted by us, representatives, affiliates, or anyone calling
                on our behalf at any contact number, or physical or electronic
                address provided by you while registering your account with
                KClub. You further agree to us contacting you in any manner,
                including without limitation, emails, SMS messages, WhatsApp,
                calls using pre-recorded messages or artificial voice, and
                notifications sent via our mobile application. You also
                acknowledge that you understand the English language and are
                most comfortable receiving communications in the English
                language.
              </li>
              <li className="mt-2">
                In our endeavour to effectively deliver the Services, we take
                your explicit consent to access your emails (registered with us)
                for the specific purpose of reading emails relevant to the
                Services, including bank statements, to access your records in
                connection with your loan application. To the extent required
                for provision of the Services, any password-protected document
                will be accessed via automated means, by utilizing information
                provided by you to automatically generate passwords in order to
                safely access such documents. It is further clarified that the
                consent sought from you shall be used solely in connection with
                the loan application facilitated through us and such information
                shall not be shared with any third party, save and except an RBI
                (“Reserve Bank of India”) registered financial institution which
                may require the aforementioned information for processing the
                loan application as well as the compliance of applicable law as
                recorded in paragraph 2 of “Services”.
              </li>
            </ol>
            <p className="mainHead">PRIVACY</p>
            <ol type="1">
              <li className="mt-2">
                You hereby acknowledge, understand and consent to the terms
                contained in KClub’s Privacy Policy which you have duly read and
                fully understood. You acknowledge, agree and give consent to
                KClub that KClub and its officers, employees, service providers
                and agents may use, store, process, disclose, transfer (whether
                within or outside India) and / or exchange your information
                (including personal data) in accordance our Privacy Policy.
              </li>
              <li className="mt-2">
                You acknowledge that our lending partners reserve the right to
                reject your application for any facility without assigning any
                reasons whatsoever without any intimation to you.
              </li>
              <li className="mt-2">
                You understand that We/ our lending partners may retrieve/
                procure/download your KYC data/ details from the Central KYC
                registry for the purposes of verifying/establishing the
                data/details/identity and you hereby allow us/them to do so.
              </li>
            </ol>
            <p className="mainHead">LIMITATION OF LIABILITY</p>
            <ol type="1">
              <li className="mt-2">
                All content and services provided on the website are provided on
                an “as is” and “as available” basis. KClub expressly disclaims
                all warranties of any kind, whether express or implied,
                including, but not limited to, the implied warranties of
                merchantability, fitness for a particular purpose, title,
                non-infringement, and security and accuracy, as well as all
                warranties arising by usage of trade, course of dealing, or
                course of performance. KClub makes no warranty, and expressly
                disclaims any obligation, that: (a) the content will be
                up-to-date, complete, comprehensive, accurate or applicable to
                your circumstances; (b) the website will meet your requirements
                or will be available on an uninterrupted, timely, secure, or
                error-free basis; (c) the results that may be obtained from the
                use of the website or any services offered through the site will
                be accurate or reliable; or (d) the quality of any products,
                services, information, or other material obtained from you
                through the site will meet your expectations.
              </li>
              <li className="mt-2">
                KClub (including its officers, directors, employees,
                representatives, affiliates, and providers) will not be
                responsible or liable for (a) any injury, death, loss, claim,
                act of god, accident, delay, or any direct, special, exemplary,
                punitive, indirect, incidental or consequential damages of any
                kind (including without limitation lost profits or lost
                savings), whether based in contract, tort, strict liability or
                otherwise, that arise out of or is in any way connected with (i)
                any failure or delay (including without limitation the use of or
                inability to use any component of the website), or (ii) any use
                of the website or content, or (iii) the performance or
                non-performance by KClub or any provider or (b) any damages to
                or viruses that may infect your computer equipment or other
                property as the result of your access to the website or your
                downloading of any content from the website.
              </li>
              <li className="mt-2">
                While we strive to be accurate in our assessment of your
                eligible credit limit, you must acknowledge that the final
                decisions regarding the loan/credit being provided to you is
                taken by our lending partner. You agree to not hold KClub liable
                if any lending partner does not make credit available to you.
              </li>
            </ol>
            <p className="mainHead">ASSIGNMENT</p>
            <p className="mt-2">
              You will not assign or otherwise transfer your rights or
              obligations under these Terms. KClub may assign its rights and
              duties under these Terms without any such assignment being
              considered a change to the Terms and without any notice to you.
            </p>
            <p className="mainHead">ARBITRATION AND GOVERNING LAW</p>
            <p className="mt-2">
              The laws of India, without regard to its conflict of laws rules,
              will govern these Terms. In case of any legal action courts
              located in Mumbai, India will have jurisdiction over such matters.
              In any proceedings initiated by or against us, KClub will be
              entitled to recover all legal expenses incurred in connection with
              the legal action, including but not limited to costs, both taxable
              and non-taxable, and attorney fees.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
