import React, {useEffect, useState} from "react";
import appstore from "../assets/images/appstore.png"
import playstore from "../assets/images/playstore.png"
import logo1 from "../assets/images/Group_Black1.png"
import { CommingSoon } from "./CommingSoon";
import Modal from "react-modal";
import { HashLink } from "react-router-hash-link"


  const Header = () => {

  const [openNav, setOpenNav] = useState(false)

  const [modalIsOpen, setIsOpen] = useState(false);

  const [url, setUrl] = useState(window.location.origin)

  // window.location.reload(null); 
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "transparent",
      border: "none"
    },
  };

  const showCommingSoon = () => {
    setOpenNav(false)
    setIsOpen(true)
  }

  const onClickNav = () => {
    setOpenNav(true)
  }  

  const onCloseNav =() => {
    setOpenNav(false)
  }
  return (

    
    <div >
      <Modal
    isOpen={modalIsOpen}
    // onAfterOpen={afterOpenModal}
    onRequestClose={()=> setIsOpen(false)}
    style={customStyles}
    contentLabel="Example Modal"
  >
    {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
    {/* <button>close</button> */}
    <div>
    <div onClick={() => setIsOpen(false)}>

    <i className="bi bi-x" style={{marginRight: "20", fontSize: 30, marginLeft:150, marginBottom: 100, cursor: "pointer"}} onClick={() => setIsOpen(false)}></i>
    </div>
    <CommingSoon />
    </div>
    
   
  </Modal>
      <header id="header" className="fixed-top">
        <div className="container p-3 p-lg-0 d-flex align-items-center justify-content-between">
          <h1 className="logo ">
            <a href={url}>
              <img src={logo1}/>
            </a>
          </h1>

          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto " href={url +"#hero"}>
                  Home
                </a>
              </li>
             
              <li>
              </li>
              <li>
                <a className="nav-link scrollto" href={url + "#features"}>
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto " href={url + "#portfolio"}>
                  Portfolio
                </a>
              </li>
              <li>
              
                <a className="nav-link scrollto" href={url + "#pricing"}>
               
                  Team
                  </a>
                
              </li>
              <li>
                <a className="nav-link scrollto" href={url + "#contact"}>
                  Contact
                </a>
              </li>
              <li>
                <div className="getstarted scrollto" href={url + "#about"}>
                  <img style={{marginRight
                  :"10px", cursor: "pointer"}} src={playstore} onClick={() => setIsOpen(true)}/>
                  <img src={appstore} style={{cursor: "pointer"}}  onClick={() => setIsOpen(true)}/>
                </div>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"  onClick={onClickNav}></i>
          </nav>
          {openNav && <nav id="navbar" className="dropdown navbar-mobile mobile-nav-toggle'">
       
            <ul>
            <i className="bi bi-x mobile-nav-toggle" onClick={onCloseNav} ></i>

              <li>
                <a className="nav-link scrollto dropdown-active"  href={url + "#hero"} onClick={onCloseNav}>
                  Home
                </a>
              </li>
             
              <li>
                <a className="nav-link scrollto dropdown-active" href={url + "#features"} onClick={onCloseNav}>
                  Services
                </a>
              </li>
              <li>
                <a className="nav-link scrollto dropdown-active" href={url + "#portfolio"} onClick={onCloseNav}>
                  Portfolio
                </a>
              </li>
              <li>
                <a className="nav-link scrollto dropdown-active" href={url + "#pricing"} onClick={onCloseNav}>
                  Team
                </a>
              </li>
              <li>
                <a className="nav-link scrollto dropdown-active" href={url + "#contact"} onClick={onCloseNav}>
                  Contact
                </a>
              </li>
              <li>
                <div className="getstarted scrollto" href={url + "#about"}>
                  <img src={playstore} style={{cursor: "pointer"}} onClick={showCommingSoon} /> &nbsp;
                  <img src={appstore} style={{cursor: "pointer"}} onClick={showCommingSoon}/>
                </div>
              </li>
            </ul>
      
          </nav>}
        </div>
      </header>
    </div>
  );
};

export default Header;
