import React, {useState} from "react";
// import 

import client1 from "../assets/images/clients/_Client_logo-1.png"
import client2 from "../assets/images/clients/_Client logo-2.png"
import client3 from "../assets/images/clients/_Client logo-3.png"
import client4 from "../assets/images/clients/_Client logo-4.png"
import client5 from "../assets/images/clients/_Client logo-5.png"
import client6 from "../assets/images/clients/_Client logo.png"

import feature1 from "../assets/images/features/Base feature icon.png"
import feature2 from "../assets/images/features/Base_feature_icon-1.png"
import feature3 from "../assets/images/features/Base feature icon-2.png"
import feature4 from "../assets/images/features/Base feature icon-4.png"
import feature5 from "../assets/images/features/Base feature icon-5.png"

// import img1 from "../assets/images/clients/Untitled (470 × 200 px).png"
// /Users/kclubtech/Desktop/kclub/website/src/assets/images/clients/Untitled (470 × 200 px).png
import feature6 from "../assets/images/features/Base feature icon-6.png"
import logos from "../assets/images/Logos.png"
import logo from "../assets/images/Group 1white_logo.png"
import appstore from "../assets/images/appstore.png"
import playstore from "../assets/images/playstore.png"
import mobileapp from "../assets/images/double_mobile.png"
import flight from "../assets/images/flight 1.svg"
import car from "../assets/images/car 1.svg"
import health from "../assets/images/life-insurance 1.svg"
import testimonials from "../assets/images/testi.png"
import { CommingSoon } from "./CommingSoon";
import Modal from "react-modal";

const  axis = "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/lenders/axis.png"


const afbl = "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/lenders/adityabirla.png"

const idfc =  "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/lenders/idfc.png"
const lnt = "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/lenders/L%26T.png"
const fintree = "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/lenders/fintree.png"
const  comfort = "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/lenders/comfort.png"


const Features = () => {


  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "transparent",
      border: "none"
    },
  };

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    console.log("here");
    setIsOpen(false);
  }

  return (
    <main id="main">
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button>close</button> */}
        <div>
          <div onClick={() => setIsOpen(false)}>
            <i
              className="bi bi-x"
              style={{
                marginRight: "20",
                fontSize: 30,
                marginLeft: 150,
                marginBottom: 100,
                cursor: "pointer",
              }}
              onClick={openModal}
            ></i>
          </div>
          <CommingSoon />
        </div>
      </Modal>
      {/* <div>
      <CommingSoon/>
    </div> */}
      {/* <section id="about" className="about">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
              data-aos="fade-right"
            >
              <h3>Voluptatem dignissimos provident quasi corporis</h3>
              <p className="fst-italic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <ul>
                <li>
                  <i className="bi bi-check-circle"></i> Ullamco laboris nisi ut
                  aliquip ex ea commodo consequat.
                </li>
                <li>
                  <i className="bi bi-check-circle"></i> Duis aute irure dolor
                  in reprehenderit in voluptate velit.
                </li>
                <li>
                  <i className="bi bi-check-circle"></i> Ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in
                  reprehenderit in voluptate trideta storacalaperda mastiro
                  dolore eu fugiat nulla pariatur.
                </li>
              </ul>
              <a href="kclub.me" className="read-more">
                Read More <i className="bi bi-long-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section> */}

      <section id="client" className="counts">
        <div className="container" data-aos="zoom-in" data-aos-delay="100">
          <div className="section-title">
            <h2>Our Merchants</h2>
            {/* <p>We offer a range of loan products, including:</p> */}
          </div>
          <div className="row counters">
            <div className="col-lg-2 col-4 p-0   text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="232"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Clients</p> */}
              <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/acko.png"} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>

            <div className="col-lg-2  p-0  col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="521"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Projects</p>
               */}
              <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/siloho.png"} alt="" style={{ padding: "10px" , width: "100%" }} />
            </div>

            <div className="col-lg-2 p-0     col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="1463"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hours Of Support</p> */}
              <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/turtlemint.png"} alt="" style={{ padding: "10px" , width: "100%" }} />
            </div>

            <div className="col-lg-2  p-0  col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hard Workers</p>
               */}
              <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/yatripay.png"} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>
            <div className="col-lg-2 p-0 col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="1463"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hours Of Support</p> */}
              <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/cars24.png"} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>

            <div className="col-lg-2 p-0 col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hard Workers</p>
               */}
              <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/servify.png"} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>
          </div>
        </div>
      </section>
      <section id="lender" className="counts">
        <div className="container" data-aos="zoom-out" data-aos-delay="100">
          <div className="section-title">
            <h2>Lenders</h2>
            {/* <p>We offer a range of loan products, including:</p> */}
          </div>
          <div className="row counters">
            <div className="col-lg-2 col-4 p-0    text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="232"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Clients</p> */}
              <img src={axis} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>

            <div className="col-lg-2 p-0   col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="521"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Projects</p>
               */}
              <img src={idfc} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>

            <div className="col-lg-2  p-0   col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="1463"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hours Of Support</p> */}
              <img src={afbl} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>

            <div className="col-lg-2  p-0  col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hard Workers</p>
               */}
              <img src={lnt} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>
            <div className="col-lg-2 p-0  col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="1463"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hours Of Support</p> */}
              <img src={fintree} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>

            <div className="col-lg-2 p-0 col-4 text-center">
              <span
                data-purecounter-start="0"
                data-purecounter-end="15"
                data-purecounter-duration="1"
                className="purecounter"
              ></span>
              {/* <p>Hard Workers</p>
               */}
              <img src={comfort} alt="" style={{ padding: "10px", width: "100%" }} />
            </div>
          </div>
        </div>
      </section>

      {/* <section id="services" className="services section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Services</h2>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div>

          <div className="row gy-4">
            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box iconbox-blue">
                <div className="icon">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      strokeWidth="0"
                      fill="#f5f5f5"
                      d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
                    ></path>
                  </svg>
                  <i className="bx bxl-dribbble"></i>
                </div>
                <h4>
                  <a href="kclub.me">Lorem Ipsum</a>
                </h4>
                <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box iconbox-orange ">
                <div className="icon">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      strokeWidth="0"
                      fill="#f5f5f5"
                      d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
                    ></path>
                  </svg>
                  <i className="bx bx-file"></i>
                </div>
                <h4>
                  <a href="kclub.me">Sed Perspiciatis</a>
                </h4>
                <p>
                  Duis aute irure dolor in reprehenderit in voluptate velit esse
                  cillum dolore
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box iconbox-pink">
                <div className="icon">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      strokeWidth="0"
                      fill="#f5f5f5"
                      d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
                    ></path>
                  </svg>
                  <i className="bx bx-tachometer"></i>
                </div>
                <h4>
                  <a href="kclub.me">Magni Dolores</a>
                </h4>
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  qui officia
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon-box iconbox-yellow">
                <div className="icon">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      strokeWidth="0"
                      fill="#f5f5f5"
                      d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"
                    ></path>
                  </svg>
                  <i className="bx bx-layer"></i>
                </div>
                <h4>
                  <a href="kclub.me">Nemo Enim</a>
                </h4>
                <p>
                  At vero eos et accusamus et iusto odio dignissimos ducimus qui
                  blanditiis
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="icon-box iconbox-red">
                <div className="icon">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      strokeWidth="0"
                      fill="#f5f5f5"
                      d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"
                    ></path>
                  </svg>
                  <i className="bx bx-slideshow"></i>
                </div>
                <h4>
                  <a href="kclub.me">Dele Cardo</a>
                </h4>
                <p>
                  Quis consequatur saepe eligendi voluptatem consequatur dolor
                  consequuntur
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 d-flex align-items-stretch"
              data-aos="zoom-in"
              data-aos-delay="300"
            >
              <div className="icon-box iconbox-teal">
                <div className="icon">
                  <svg
                    width="100"
                    height="100"
                    viewBox="0 0 600 600"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke="none"
                      strokeWidth="0"
                      fill="#f5f5f5"
                      d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
                    ></path>
                  </svg>
                  <i className="bx bx-arch"></i>
                </div>
                <h4>
                  <a href="kclub.me">Divera Don</a>
                </h4>
                <p>
                  Modi nostrum vel laborum. Porro fugit error sit minus sapiente
                  sit aspernatur
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section id="features" className="features">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Products</h2>
            <p>We offer a range of loan products, including:</p>
          </div>

          <div className="row">
            <div className="col-lg-12  d-flex flex-column align-items-lg-center">
              <div className="row">
                <div
                  className="col-lg-6 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  {/* <i className="bx bx-receipt"></i> */}
                  <h4>
                    <img src={flight} /> &nbsp; Travel
                  </h4>
                  <p>
                    At KClub, we understand the importance of travel and the
                    enriching experiences it brings. That's why we offer a
                    travel loan option, tailored to make your dream vacation or
                    travel experience a reality. Our travel loans come with
                    competitive interest rates and flexible repayment options,
                    making it easy for you to finance your travels without
                    financial stress. We want you to explore the world and
                    create unforgettable memories, so let us take care of the
                    finances.
                  </p>
                </div>
                <div
                  className="col-lg-6 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  {/* <i className="bx bx-cube-alt"></i> */}
                  <h4>
                    <img src={car} /> &nbsp; Car{" "}
                  </h4>
                  <p>
                    At KClub, we know that owning a car can be an essential part
                    of your daily life. Our car loan option is designed to make
                    it easier for you to purchase your dream car. With
                    competitive interest rates and flexible repayment options,
                    our car loan makes it simple for you to own the car you've
                    always wanted. We understand that each customer's needs are
                    unique, so we provide personalized solutions to ensure that
                    the car loan fits your specific requirements. Whether you're
                    buying a new car or a used one, KClub's car loan option is
                    the perfect way to finance your purchase with ease.
                  </p>
                </div>
                <div
                  className="col-lg-6 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  {/* <i className="bx bx-images"></i> */}
                  <h4>
                    <img
                      src={
                        "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/electronics.svg"
                      }
                    />{" "}
                    &nbsp; Electronics
                  </h4>
                  <p>
                    KClub offers a mobile loan option to help you upgrade to the
                    latest smartphone with ease. With competitive interest rates
                    and easy repayment options, you can stay connected and
                    up-to-date with the latest technology trends. Our
                    application process is hassle-free, and you can apply for
                    the loan online with minimal paperwork. We provide
                    personalized solutions to ensure that the mobile loan fits
                    your specific requirements. Don't let finances hold you back
                    from upgrading your phone, choose KClub's mobile loan
                    option.
                  </p>
                </div>
                <div
                  className="col-lg-6 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <h4>
                    <img
                      src={
                        "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/umbrella.svg"
                      }
                    />{" "}
                    &nbsp; Insurance
                  </h4>
                  <p>
                    KClub understands that sometimes, insurance premiums can put
                    a strain on your finances. That's why we offer insurance
                    category loans to help you manage your insurance costs with
                    ease. Our loans come with competitive interest rates and
                    flexible repayment options, making it simple for you to pay
                    your insurance premiums on time. We provide personalized
                    solutions to ensure that the loan fits your specific
                    requirements. With minimal paperwork and hassle-free
                    processing, our platform provides a quick and easy way to
                    apply for insurance category loans online. Let KClub take
                    care of your insurance costs, so you can have peace of mind.
                  </p>
                </div>
                <div
                  className="col-lg-6 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  {/* <i className="bx bx-images"></i> */}
                  <h4>
                    <img
                      src={
                        health
                      }
                    />{" "}
                    &nbsp; Health
                  </h4>
                  <p>
                    KClub offers a range of health insurance products with
                    comprehensive coverage for hospitalization expenses and
                    medical treatments. Our policies come with competitive
                    premiums and hassle-free claim settlement procedures, giving
                    you peace of mind. Choose KClub to take care of your
                    healthcare needs and live a worry-free life.
                  </p>
                </div>
                <div
                  className="col-lg-6 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <h4>
                    <img
                      src={
                        "https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/house.svg"
                      }
                    />{" "}
                    &nbsp; Home Furnishing
                  </h4>
                  <p>
                    Furnishing your home can be expensive, which is why we offer
                    a loan category specifically for home furnishing. Our home
                    furnishing loans provide you with the financial support you
                    need to turn your house into a dream home. With competitive
                    interest rates and easy repayment options, you can furnish
                    your home without worrying about finances.
                  </p>
                </div>
                {/*<div
                  className="col-lg-4 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <img src={feature6} />

                  <h4>Feature List</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    erat nibh tristique ipsum.
                  </p>
                </div>
                <div
                  className="col-lg-4 col-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <img src={feature5} />

                  <h4>Feature List</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    erat nibh tristique ipsum.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section id="testimonials" className="testimonials section-bg">
        <div className="container" data-aos="fade-up"> */}
      {/* <div className="section-title">
            <h2>Testimonials</h2>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div> */}
      {/* <img src={testimonials} /> */}
      {/* <div
            className="testimonials-slider swiper"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Proin iaculis purus consequat sem cure digni ssim donec
                    porttitora entum suscipit rhoncus. Accusantium quam,
                    ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                    risus at semper.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <h3>Saul Goodman</h3>
                  <h4>Ceo &amp; Founder</h4>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Export tempor illum tamen malis malis eram quae irure esse
                    labore quem cillum quid cillum eram malis quorum velit fore
                    eram velit sunt aliqua noster fugiat irure amet legam anim
                    culpa.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <h3>Sara Wilsson</h3>
                  <h4>Designer</h4>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Enim nisi quem export duis labore cillum quae magna enim
                    sint quorum nulla quem veniam duis minim tempor labore quem
                    eram duis noster aute amet eram fore quis sint minim.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <h3>Jena Karlis</h3>
                  <h4>Store Owner</h4>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                    multos export minim fugiat minim velit minim dolor enim duis
                    veniam ipsum anim magna sunt elit fore quem dolore labore.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <h3>Matt Brandon</h3>
                  <h4>Freelancer</h4>
                </div>
              </div>

              <div className="swiper-slide">
                <div className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    Quis quorum aliqua sint quem legam fore sunt eram irure
                    aliqua veniam tempor noster veniam enim culpa labore duis
                    sunt culpa nulla illum cillum fugiat legam esse veniam
                    culpa.
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <h3>John Larson</h3>
                  <h4>Entrepreneur</h4>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div> */}
      {/* <div className="row">
            <div className="col-lg-1 col-12"></div>
            <div className="col-lg-5 col-12">
              <p style={{ marginTop: "30px" }}>Get Inspired by these stories</p>

              <div
                className="card"
                style={{ marginLeft: "0%", marginTop: "10%" }}
              >
                <div className="p-4">
                  <img src={client3} alt="" className="mb-3" />
                  <div className="row mb-3">
                    <div className="col-1">
                    </div>
                    <div className="col-11">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text since 1500s. simply dummy
                        text of the printing. Lorem Ipsum has been the
                        industry's standard dummy text.
                      </p>

                      <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                        Floyd Miles
                      </p>
                      <p style={{ marginBottom: "0" }}>Vice President, GoPro</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="card">
                <div className="p-4">
                  <img src={client1} alt="" className="mb-3" />
                  <div className="row mb-3">
                    <div className="col-1">
                    </div>
                    <div className="col-11">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text since 1500s.
                      </p>

                      <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                        Jane Cooper
                      </p>
                      <p style={{ marginBottom: "0" }}>CEO, Airbnb</p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <br />
              <div className="card">
                <div className="p-4">
                  <img src={client4} alt="" className="mb-3" />
                  <div className="row mb-3">
                    <div className="col-1">
                    </div>
                    <div className="col-11">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text since 1500s.
                      </p>

                      <p style={{ fontWeight: "bold", marginBottom: "0" }}>
                        Jane Cooper
                      </p>
                      <p style={{ marginBottom: "0" }}>CEO, Airbnb</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1 col-12"></div>
          </div> */}
      {/* </div>
      </section> */}

      <section id="portfolio" className="portfolio">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 col-lg-4 text-center text-lg-start">
              <h2 style={{ color: "#1F1F1F" }}>Our unbeatable achievements</h2>
              <p>With our super powers we have reached this</p>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row text-center">
                <div className="col-lg-12 col-12 d-flex flex-column flex-wrap">
                  <div className="row">
                    <div className="col-6 col-lg-6">
                      <div className="row">
                        <div className="col-lg-1 col-2 mt-3">
                          <img src={feature1} />
                        </div>
                        <div className="col-lg-5 col-10">
                          <h3 className="mb-0"> 10,000+</h3>
                          <p>Downloads per day</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6">
                      <div className="row">
                        <div className="col-lg-1 col-2 mt-3">
                          <img src={feature2} />
                        </div>
                        <div className="col-lg-5 col-8">
                          <h3 className="mb-0">10K</h3>
                          <p>Users</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6">
                      <div className="row">
                        <div className="col-lg-1 col-2 mt-3">
                          <img src={feature3} />
                        </div>
                        <div className="col-lg-5 col-8">
                          <h3 className="mb-0">50+</h3>
                          <p>Merchants</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-lg-6">
                      <div className="row">
                        <div className="col-lg-1 col-2 mt-3">
                          <img src={feature4} />
                        </div>
                        <div className="col-lg-5 col-8">
                          <h3 className="mb-0">5</h3>
                          <p>Countries</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="integration" className="portfolio pt-0">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-12 col-lg-4 px-3 py-5">
              <h2 style={{ color: "#1F1F1F" }}>
              Integration with 20+ Vendors
              </h2>
              <p>
                We are constantly innovating and investing in new technologies
                to enhance our services and provide the best possible experience
                for our customers. Our state-of-the-art online platform allows
                customers to access our products and services anytime, anywhere,
                making it more convenient and hassle-free.
              </p>
            </div>
            <div className="col-12 col-lg-8 text-center text-lg-left">
              {/* <div className="row">
                <div className="col-lg-12 col-12 d-flex flex-column flex-wrap"> */}
                  {/* <div className="row"> */}
                  {/* <div> */}
                    {/* <hr style={{ margin: "0px" }} /> */}

                    <img src={"https://kclub-bucket.s3.ap-south-1.amazonaws.com/static/integration.png"} width="40%" height={"100%"} />
                  {/* </div> */}

                  {/* </div> */}
                {/* </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="row">
            <div className="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" className="filter-active">
                  All
                </li>
                <li data-filter=".filter-app">App</li>
                <li data-filter=".filter-card">Card</li>
                <li data-filter=".filter-web">Web</li>
              </ul>
            </div>
          </div> */}
      </section>

      <section id="pricing" className="pricing pricing-bg pt-0 pb-0 ">
        <div className="container" data-aos="fade-up">
          {/* <div className="section-title"> */}
          <div className="row">
            <div className="col-12 col-lg-6 px-4 py-5">
              <h2>Join Us.</h2>
              <p>
                Join us on our mission to make financial management easy and
                accessible for everyone. Sign up today and experience the
                convenience of modern banking with Kclub Technologies.{" "}
              </p>
              <p>Get the App</p>
              <img
                src={playstore}
                style={{ cursor: "pointer" }}
                onClick={() => setIsOpen(true)}
              />
              <img
                src={appstore}
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => setIsOpen(true)}
              />
            </div>
            {/* <div className="col-lg-2">
</div> */}
            <div className="col-12 col-lg-6">
              <img src={mobileapp} width={"100%"} />
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>

      {/* <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Frequently Asked Questions</h2>
            <p>
              Magnam dolores commodi suscipit. Necessitatibus eius consequatur
              ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam
              quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea.
              Quia fugiat sit in iste officiis commodi quidem hic quas.
            </p>
          </div>

          <div className="faq-list">
            <ul>
              <li data-aos="fade-up" data-aos-delay="100">
                <i className="bx bx-help-circle icon-help"></i>{" "}
              
                <div
                  id="faq-list-1"
                  className="collapse show"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id
                    volutpat lacus laoreet non curabitur gravida. Venenatis
                    lectus magna fringilla urna porttitor rhoncus dolor purus
                    non.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="200">
                <i className="bx bx-help-circle icon-help"></i>{" "}
               
                <div
                  id="faq-list-2"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Dolor sit amet consectetur adipiscing elit pellentesque
                    habitant morbi. Id interdum velit laoreet id donec ultrices.
                    Fringilla phasellus faucibus scelerisque eleifend donec
                    pretium. Est pellentesque elit ullamcorper dignissim. Mauris
                    ultrices eros in cursus turpis massa tincidunt dui.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="300">
                <i className="bx bx-help-circle icon-help"></i>{" "}
             
                <div
                  id="faq-list-3"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Eleifend mi in nulla posuere sollicitudin aliquam ultrices
                    sagittis orci. Faucibus pulvinar elementum integer enim. Sem
                    nulla pharetra diam sit amet nisl suscipit. Rutrum tellus
                    pellentesque eu tincidunt. Lectus urna duis convallis
                    convallis tellus. Urna molestie at elementum eu facilisis
                    sed odio morbi quis
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="400">
                <i className="bx bx-help-circle icon-help"></i>{" "}
             
                <div
                  id="faq-list-4"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Molestie a iaculis at erat pellentesque adipiscing commodo.
                    Dignissim suspendisse in est ante in. Nunc vel risus commodo
                    viverra maecenas accumsan. Sit amet nisl suscipit adipiscing
                    bibendum est. Purus gravida quis blandit turpis cursus in.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="500">
                <i className="bx bx-help-circle icon-help"></i>{" "}
            
                <div
                  id="faq-list-5"
                  className="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Laoreet sit amet cursus sit amet dictum sit amet justo.
                    Mauris vitae ultricies leo integer malesuada nunc vel.
                    Tincidunt eget nullam non nisi est sit amet. Turpis nunc
                    eget lorem dolor sed. Ut venenatis tellus in metus vulputate
                    eu scelerisque.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section> */}
    </main>
  );
};

export default Features;
