import { faListSquares } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const PrivacyPolicy = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <div className="row text-center">
          <div
            className="col-12 order-1 order-lg-2"
            data-aos="zoom-in"
            data-aos-delay="150"
          ></div>
          <div
            className="col-12 pt-4 pt-lg-0 order-2 order-lg-1 content"
            data-aos="fade-right"
          >
            <h3>Our Privacy Policy</h3>

            <div className="text-start">
              <p>
                KClub (KClub Technologies Private Limited) (“we” or “us” or
                “our” or the “Company”) respects the privacy of its members
                (“you” or “your”) and has developed this Privacy Policy to
                demonstrate its commitment to protecting your privacy.
              </p>
              <p>
                KClub is a company engaged in the business of operating an
                online technology platform for the purpose of providing a
                platform for persons to register for obtaining loans from
                financial institutions in India. By using the Website or
                availing the services provided by us (“Service”), you are
                accepting the practices described in this Privacy Policy.{" "}
              </p>
              <p>
                This Privacy Policy describes the information we collect, how
                that information may be used, with whom it may be shared, and
                your choices about such usage and disclosures. You must read
                this Privacy Policy carefully when using the KClub platform or
                transacting with us.
              </p>
              <p>
                If you see an undefined term in this Privacy Policy, it has the
                same definition as outlined in the Terms and Conditions of Use,
                which you may access here:{" "}
                <a href="https://kclub.me/terms-and-conditions">
                  https://kclub.me/terms-and-conditions
                </a>
              </p>
              <p className="mainHead">1. INFORMATION WE COLLECT</p>
              <p>
                <span>1.1 </span>{" "}
                <span> Sensitive Data and Personal Information:</span> In
                general, We may collect Personal Information, including
                Sensitive Data.
              </p>
              <p>
                “Personal Information” means individually identifiable
                information that directly or indirectly, in combination with
                other information would allow the identification of a specific
                living person.
              </p>
              <p>
                “Sensitive Data” means Your Personal Information such as
                financial information such as bank account details and/ or any
                other payment instrument details, sexual orientation, any
                details that may have been voluntarily provided by You and any
                of the information received under above clauses by Us in
                connection with availing the Services.
              </p>
              <p>
                By providing Personal Information (including Sensitive Data) to
                us, you consent to the collection, usage, and disclosure of
                Personal Information (including Sensitive Data), as permitted by
                applicable laws. By using the Services, you are authorizing us
                to gather, parse data and retain basic data which are related to
                the provision of the Services.
              </p>
              <p>
                <span>1.2  </span> <span> Personal Information: </span>
                We may collect and process your Personal Information, including
                but not limited to the following:
              </p>
              <p>
                <span>1.2.1  </span> Information provided by you at the time of
                registration such as your name, address, email ID, phone number,
                bank account details, financial information, unique identifiers,
                and preferences information including favourites and history;
              </p>
              <p>
                <span>1.2.2  </span> Information that you provide to us directly
                via email and/ or electronic communication;
              </p>
              <p>
                <span>1.2.3  </span> Your bank account statement provided by you
                through the upload feature during the onboarding process on
                KClub Platform to assess your creditworthiness;
              </p>
              <p>
                <span>1.2.4 </span> Your live selfie for your identity check and
                verification with your KYC documents. The image captured through
                this selfie may be shared with third party for the purpose
                mentioned in Clause 3.3 herein;
              </p>
              <p>
                <span>1.2.5 </span> Information that you provide to us over
                telephone. We may make and keep a record of such information
                shared by you;
              </p>
              <p>
                <span>1.2.6 </span> nformation that you provide to us in physical
                form whether sent through post and / or courier and / or handed
                over to our representatives in person;
              </p>
              <p>
                <span>1.2.7 </span> Any other additional information required to
                be provided via email/ electronic communication/ telephone/
                post/ courier after your registration; and
              </p>
              <p>
                <span>1.2.8 </span> Hardware model, operating system and versions
                and identifiers like IMEI number and serial number of your
                primary device used for availing Services.
              </p>
              <p>
                <span>1.2.9 </span> Information from other sources.
              </p>
              <p>
                <span>1.3  </span> <span> Domain Name and IP Address: </span>
                We may collect your domain name which is linked to every
                computer/ mobile device connected to the internet and a set of
                numbers that serve as that computer’s Internet Protocol or “IP”
                address. When you request a page from any page within the
                Website, our web servers automatically recognize your domain
                name and IP address. The domain name and IP address does not
                reveal any further information about you other than the IP
                address from which you have accessed the Website.
              </p>
              <p>
                <span>1.4 </span> <span>Cookies:</span>
                Cookies are small portions of information saved by your browser
                onto your computer/ mobile device. Cookies are used to record
                various aspects of your visit and assist us to provide you with
                uninterrupted service. We do not use cookies to save Personal
                Information for unauthorized uses.
              </p>
              <p>
                <span>1.5 </span>{" "}
                <span> Access to your registered email account:</span>
                You may opt to connect your email account to KClub platform, and
                you may explicitly provide your consent to KClub to securely
                access your emails solely in connection with availing the
                Services. Subject to your consent, KClub may access your emails
                to access/ extract the statements received from your bank(s) for
                the purpose of processing your application in accordance with
                the Terms and Conditions of Use. In the event you enable us to
                access your registered email, we shall view and analyse and
                extract information/contents of the emails which are sent from
                your bank(s) communicating the statement of your account which
                you may periodically receive from your bank(s). This information
                is collected to automatically assist you with your loan
                application and held under strict confidence in accordance with
                the applicable law. Under no circumstances shall we access any
                personal messages/emails and our automated process shall adhere
                to accessing emails which you had consented and more
                particularly restrict ourselves to viewing the emails
                communicating the bank statements. We shall at all times ensure
                that the specific consent is not misused, and the algorithm is
                programmed to avoid any human intervention which may act beyond
                the consent explicitly vested with us.
              </p>
              <p>
                <span>1.6 </span> <span> SMS:</span>
                We do not collect, read, store or share with third parties your
                personal SMS, other than the SMS pertaining to your financial
                transactions solely to assist our lending partners in assessing
                your credit risk.
              </p>
              <p>
                <span>1.7 </span> <span> Phone: </span>
                To prevent fraud and ensure no unauthorized devices are acting
                on your behalf on the platform we collect and monitor specific
                information about your device including its hardware model,
                operating system and versions and identifiers like IMEI number
                and serial number.
              </p>
              <p>
                We shall also take one-time access of certain features of your
                mobile phone to facilitate on-boarding on the platform and KYC
                verification:
              </p>
              &nbsp;&#9642;Camera
              <br />
              &nbsp;&#9642;Microphone
              <br />
              &nbsp; &#9642;Location
              <br />
              &nbsp;&#9642;SMS
              <br />
              &nbsp; &#9642;IP Address
              <br />
              <p>
                <span>1.8 </span> <span> Biometric Data: </span>
                No Personal Information of yours containing biometric data will
                be collected or stored by KClub unless permitted by extant
                statutory guidelines.
              </p>
              <p className="mainHead">2. HOW WE USE INFORMATION WE COLLECT</p>
              <p>
                Our primary goal in collecting the information is to provide you
                a platform to render our Services. We may use the personal
                information provided by you for one or more of the following:
              </p>
              <ol type="a">
                <li>To help provide you the Services;</li>
                <li>
                  To observe, improve and administer the quality of Services;
                </li>
                <li>
                  To analyze how the platform is used and diagnose technical
                  problems;
                </li>
                <li>
                  {" "}
                  Remember the basic information provided by you for effective
                  access;
                </li>
                <li>
                  To confirm your identity in order to determine your
                  eligibility to use the platform and avail the Services
                </li>
                <li>To notify you about any changes to the Website;</li>
                <li>
                  To enable us to comply with our legal and regulatory
                  obligations;
                </li>
                <li>
                  {" "}
                  For the purpose of sending administrative notices,
                  Service-related alerts and other similar communication with a
                  view to optimizing the efficiency of the Website;
                </li>
                <li>
                  Doing market research, troubleshooting, protection against
                  error, project planning, fraud and other criminal activity;
                  and
                </li>
                <li>To enforce our Terms and Conditions of Use.</li>
              </ol>
              <p className="mainHead">3. DISCLOSURE OF INFORMATION</p>
              <p>
                <span>3.1  </span>
                We do not publish your Sensitive Data provided by you.
              </p>
              <p>
                <span>3.2  </span>
                We will treat your Personal Information strictly confidential.
                We will not disclose your Personal Information to anyone, except
                in the following cases
              </p>
              <ol type="1">
                <li>
                  We may disclose your Personal Information in the event it is
                  required to do so by law, rule, regulation, law, enforcement,
                  governmental official, legal or regulatory authorities and/ or
                  to such other statutory bodies who have appropriate
                  authorisation to access the same for any specific legal
                  purposes.
                </li>
                <li>
                  <span>Merger, acquisitions:</span>
                  Information collected from you may be transferred to a third
                  party(ies) as a result of a sale or acquisition, merger or
                  bankruptcy involving the Company.
                </li>
                <li>
                  We may disclose your information to any of our affiliates or
                  related entities. We will ensure that our affiliates and
                  related entities maintain the same standards of
                  confidentiality and protection with respect to the Personal
                  Information shared with them.
                </li>
                <li>
                  We may disclose your information in order to provide you the
                  Services, enforce or apply the Terms and Conditions of Use, or
                  to protect the rights, property or safety of the Company, its
                  users or others. This includes providing information to one or
                  more lending partners, and exchanging information with other
                  companies / agencies that work for fraud prevention
                </li>
              </ol>
              <p>
                <span>3.3  </span>
                <span>Sharing: </span>
                You hereby consent to sharing your Personal information with
                credit information companies, analytics, technical partners,
                payment facilitators, commercial partners, etc for purposes
                including but not limited to providing and monitoring Our
                Services, onboarding process on the KClub Platform, assessing
                the creditworthiness, KYC verification, fraud prevention and
                risk evaluation. We may share aggregated demographic information
                with our partners or affiliates. This is not linked to any
                personal information that can identify an individual person. We
                will not be liable for transfer of any personal identification
                information resulting from loss or distribution of data, the
                delineation or corruption of media storage, power failures,
                natural phenomena, riots, act(s) of vandalism, sabotage,
                terrorism or any other event beyond our control. Further, our
                Privacy Policy does not cover the use of cookies by its partners
                and affiliates since we do not have access or control over such
                cookies.
              </p>
              <p>
                <span>3.4 </span>
                <span>Consulting:</span>
                We may partner with other parties to provide specific portions
                of the Services, if required. When you sign-up for the Services,
                we may share some of your Personal Information (including phone
                number registered or to be registered with KClub) and Sensitive
                data to the extent necessary for the third party to provide
                these Services. Our contractual arrangements with such third
                parties restrict these parties from using personally
                identifiable information except for the explicit purpose of
                assisting in the provision of these Services.
              </p>
              <p>
                <span>3.5 </span>
                <span>Promotional Offers:</span>
                We may send you offers, which may be on behalf of other
                businesses. If you do not want to receive such offers, you may
                request us to adjust your preferences.
              </p>
              <p className="mainHead">4. CHANGE OF INFORMATION</p>
              <p>
                If your Personal Information or Sensitive Data changes, you may
                correct, delete inaccuracies, or amend information by reaching
                out to us at help@kclub.me We may request you to provide any
                additional information or documents as may be necessary to
                verify the changes requested by you.
              </p>
              <p className="mainHead">5. SECURITY OF INFORMATION</p>
              <p>
                <span>5.1 </span>
                We take the security of your information very seriously. To this
                end, we use several security techniques including secure
                servers, firewalls, and encryptions, as well as physical
                safeguard of the locations where the data are stored. We have in
                place appropriate administrative, technical, physical safeguards
                and security measures to protect the personal data you provide
                to us against accidental, unauthorized, or unlawful loss,
                destruction, damage, alteration, access, disclosure or use and
                any other unlawful forms of processing. When we collect data, we
                collect your personal details on a secure server. We use
                firewalls on our servers. Whilst we are unable to guarantee 100%
                security, this makes it hard for a hacker to decrypt your
                details. We maintain physical, electronic, and procedural
                safeguards in connection with the collection, storage and
                disclosure of your information. Our security procedures mean
                that we may occasionally request proof of identity before we
                disclose personal information to you. You are responsible for
                protecting against unauthorized access to your password and to
                your computer.
              </p>
              <p>
                <span>5.2  </span>
                The information that is collected from you may be transferred
                to, stored, and processed at any destination as permitted under
                applicable law. By submitting information to us, you agree to
                this transfer, storing and/ or processing. We will take such
                steps as we consider reasonably necessary to ensure that your
                information is treated securely and in accordance with this
                Privacy Policy.
              </p>
              <p>
                <span>5.3 </span>
                In using the Services, you accept the inherent security
                implications of data transmission over the internet. Therefore,
                the use of the Website will be at your own risk, and we assume
                no liability for any disclosure of information due to errors in
                transmission, unauthorised third party access or other acts of
                third parties, or acts or omissions beyond its reasonable
                control and you agree not to hold us responsible for any breach
                of security.
              </p>
              <p>
                <span>5.4 </span>
                In the event we become aware of any breach of the security of
                your information, we will promptly notify you and take
                appropriate action to the best of its ability to remedy such a
                breach.
              </p>
              <p className="mainHead">6. EXCLUSION</p>
              <p>
                <span>6.1 </span>
                This Privacy Policy does not apply to any information other than
                information collected by us through the Website including such
                information collected in accordance with the clause on
                “Collection of Information” above. This Privacy Policy will not
                apply to any unsolicited information provided by you through
                this Website or through any other means. This includes, but is
                not limited to, information posted on any public areas of the
                Website. All such unsolicited information shall be deemed to be
                non-confidential, and we will be free to use, disclose such
                unsolicited information without limitation.
              </p>
              <p>
                <span>6.2  </span>
                We also protect your personal information off-line other than as
                specifically mentioned in this Privacy Policy. Access to your
                personal information is limited to employees, agents, or
                partners and third parties, who we reasonably believe will need
                that information to enable us to provide Services to you.
                However, we are not responsible for the confidentiality,
                security, or distribution of your own personal information by
                our partners and third parties outside the scope of our
                agreement with such partners and third parties.
              </p>
              <p className="mainHead">7. DATA RETENTION</p>
              <p>
                You may request us to delete your information by submitting a
                request for account deletion through the KClub mobile app via
                the personal details tab in the Profile section. On submitting a
                request to delete Your account, you confirm/understand that:
              </p>
              <ol type="1">
                <li>
                  You have no outstanding credit facility availed on the KClub
                  platform.
                </li>
                <li>
                  {" "}
                  You have no pending or potential grievances in relation to any
                  of your previous dealings with Us.
                </li>
                <li>
                  Your account will be permanently deleted. We will not be able
                  to reactivate your account.
                </li>
                <li>Your KClub credit limit will be deactivated.</li>
                <li>You will receive no further communications from KClub.</li>
              </ol>
              <p>
                You however understand that We will be required to or retain,
                and accordingly use and disclose any specific data if required
                by law, rule, regulation, law, enforcement, governmental
                official, legal, or regulatory authorities and / or to such
                other statutory bodies who have appropriate authorisation or for
                the purposes of completing any legal obligations or for audit
                requirements. We will not retain your Personal Information for
                longer than is required for the purpose for which the
                information may lawfully be used.{" "}
              </p>
              <p className="mainHead">8. OPT OUT PROCEDURES</p>
              <p>
                <span>8.1</span> Upon initial communication from us, you may
                opt-out of receiving further communications from us. To be
                completely removed from our mailing list, you may contact us at{" "}
                <a href="mailto:help@KClub.in">help@KClub.in.</a> If you are
                using an e-mail forwarding service or other similar service,
                please make sure to include the correct e-mail address or
                addresses.
              </p>
              <p>
                <span>8.2  </span>
                You are free to revoke the consent to access your email at any
                time while you avail the KClub Services. In the event you wish
                to revoke the consent or opt out from processing your
                application through us, please write to{" "}
                <a href="mailto:contact@kclub.me">contact@kclub.me.</a> Also,
                for instance, Gmail’s permission settings are located at:{" "}
                <a
                  href="https://www.myaccount.google.com/permissions"
                  target={"_blank"}
                >
                  myaccount.google.com/permissions.
                </a>{" "}
                A user may de-link the access to their email any time they wish
                by raising a request through this URL.
              </p>
              <p>9. OTHER LINKS AND WIDGETS</p>
              <p>
                <span>9.1   </span>
                The Website may contain links to other sites. The linked sites
                are not necessarily under our control. Please be aware that we
                are not responsible for the privacy practices of such other
                sites. We encourage you to read the privacy policies of each and
                every website that collects personally identifiable information.
                If you decide to access any of the third-party sites linked to
                the Website, you do this entirely at your own risk. Any links to
                any of our partners should be the responsibility of the linking
                party, and we will not be responsible for notification of any
                change in name or location of any information on the Website.
              </p>
              <p>
                <span>9.2 </span>
                The Website includes social media features, such as widgets or
                interactive mini programs that run on the Website. These
                features may collect your IP address, the page which you are
                visiting on the Website, and may set a Cookie to enable the
                feature to function properly. Social media features and widgets
                are either hosted by a third party or hosted directly on the
                Website. Your interactions with these features are governed by
                the privacy policy of the entity providing it and not the
                Company.
              </p>
              <p className="mainHead">10 NOTIFICATION OF CHANGES</p>
              <p>
                <span>10.1 </span>
                We may update this Privacy Policy at any time, with or without
                advance notice. In the event there are significant changes in
                the way we treat your Personal Information, or in the Privacy
                Policy document itself, we will display a notice on the Website
                or send you an email informing you of such changes, so that you
                may review the changed terms prior to continuing to use the
                Website and our Services.
              </p>
              <p>
                <span>10.2 </span>
                Using the Company’s Services or accessing the Website after a
                notice of changes has been sent to you or published on the
                Website will constitute your consent to the changed terms.
              </p>
              <p className="mainHead">
                11. ADDRESS FOR PRIVACY RELATED QUESTIONS
              </p>
              <p>
                <span>11.1 </span>
                We respect and are sensitive to the rights as granted in the
                data protection laws. Should you have questions about the
                Privacy Policy or our information collection, use and disclosure
                practices, you may contact the Grievance Officer as per the
                details given hereinbelow. We will use reasonable efforts to
                respond promptly to requests, questions or concerns you may have
                regarding the use of your personal information.
              </p>
              <p>
                <span>11.2  </span>
                In accordance with the Information Technology Act, 2000 and the
                rules made thereunder, the name and contact details of the
                Grievance Officer are provided below.
              </p>
              <br />
              <p>
                <span>Name</span>
                <span> Atul Kapoor</span>
              </p>
              <p>
                <span>Email Address:    </span>
                <span>
                  <a href="mailto: contact@kclub.me"> contact@kclub.me</a>
                </span>
              </p>
              <p>
                <span>Address: &nbsp;</span>
                <span>KClub Technologies Private Limited
7TH Floor, Wing B-3, Flat 706, Oberoi Sprint CTS No 705
ETC, Off Link Road, Mumbai, Maharashtra 400058</span>
              </p>
              <p>
              You may contact the Grievance Officer to address any discrepancies and grievances you may have with respect to your information available with us. The Grievance Officer will redress your grievances expeditiously.

              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
