import React from 'react';
import Lottie from "react-lottie";
import commingSoon from "../lotties/comming_soon.json"

export const CommingSoon = () => {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: commingSoon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
    <Lottie 
	    options={defaultOptions}
        height={200}
        width={200}
      />
      </div>
  )
}
