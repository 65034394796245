import "./App.css";
import {BrowserRouter as Router,
  Routes,
  Route} from "react-router-dom"
import Header from "./components/Header";
import Hero from "./components/Hero";
import Features from "./components/Features";
import { KclubFooter } from "./components/KclubFooter";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { TermsConditions } from "./components/TermsConditions";

function App() {
  return (
    <div className="App">
    <Router>
        <Header></Header>
        {/* <div className="container"> */}
        <Routes>
            <Route path="/" element={
            <Hero />
            } />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
        </Routes>
        {/* </div> */}
        <KclubFooter />

      </Router>
      <Header />
      {/* <Hero />
      <Features />
      <KclubFooter /> */}
    </div>
  );
}

export default App;
