import React, { useState } from 'react'
import logo from "../assets/images/Group 1white_logo.png"
import appstore from "../assets/images/appstore.png"
import playstore from "../assets/images/playstore.png"
import { CommingSoon } from "./CommingSoon";
import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Env from "../../src/assets/images/envelope-regular.svg"
import { Link } from 'react-router-dom';


export const KclubFooter = () => {


  const [modalIsOpen, setIsOpen] = useState(false);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "transparent",
      border: "none"
    },
  };

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    console.log("here");
    setIsOpen(false);
  }

  return (
    <main>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        
        <div>
          <div onClick={() => setIsOpen(false)}>
            <i
              className="bi bi-x"
              style={{
                marginRight: "20",
                fontSize: 30,
                marginLeft: 150,
                marginBottom: 100,
                cursor: "pointer",
              }}
              onClick={openModal}
            ></i>
          </div>
          <CommingSoon />
        </div>
      </Modal>
     <section id="contact" className="contact contact-bg">
        <div className="container" data-aos="fade-up">
          {/* <div className="row">
            <div className="col-lg-6 col-12">
              <div className="mb-4">
                <img src={logo} width={"20%"} />
              </div>
            
              <div className="row mt-2">
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a
                    className="nav-link scrollto dropdown-active"
                    href="#download"
                  >
                    {" "}
                    Download
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a
                    className="nav-link scrollto dropdown-active"
                    href="#license"
                  >
                    License
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a
                    className="nav-link scrollto dropdown-active"
                    href="#about"
                  >
                    About
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a
                    className="nav-link scrollto dropdown-active"
                    href="#features"
                  >
                    Features
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a
                    className="nav-link scrollto dropdown-active"
                    href="#pricing"
                  >
                    Pricing
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a className="nav-link scrollto dropdown-active" href="#news">
                    News
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a className="nav-link scrollto dropdown-active" href="#help">
                    Help
                  </a>
                </div>
                <div className="col-lg-3 col-3" style={{ cursor: "pointer" }}>
                  <a
                    className="nav-link scrollto dropdown-active"
                    href="#contact"
                  >
                    Contact
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6"></div>

            <div className="col-lg-3 col-md-6">
              <div className="mb-2 mt-4">
                <p className="m-1">Get the App</p>
                <img
                  src={playstore}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                />{" "}
                &nbsp; &nbsp;
                <img
                  src={appstore}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                />
              </div>
            </div>
          </div> */}
          <div className='row'>
          <div className='col-lg-4 col-6'>
          <div className="mb-4">
                <img src={logo} width={"40%"} />
                </div>
              </div>
              </div>
          <div className="row">
          <div className="col-lg-2 col-12 mt-3">
          
            <h6><b>Follow Us On</b></h6>
            <div className="row">
            <div className='col-lg-1 col-1'>
            <a  className="nav-link scrollto dropdown-active" href='https://twitter.com/kclubme' target={"_blank"}>
            <FontAwesomeIcon icon="fa-brands fa-twitter" />
</a>
            </div>
            <div className='col-lg-1 col-1'>
            <a  className="nav-link scrollto dropdown-active" href='https://www.instagram.com/kclub.me/' target={"_blank"}>

            <FontAwesomeIcon icon="fa-brands fa-instagram" />
</a>
            </div>
            {/* <div className='col-lg-1 col-1'>
            <a  className="nav-link scrollto dropdown-active" href=''>

            <FontAwesomeIcon icon="fa-brands fa-youtube" />
            </a>

            </div> */}
            <div className='col-lg-1 col-1'>
            <a  className="nav-link scrollto dropdown-active" href='https://www.linkedin.com/company/kclub-technologies/' target={"_blank"}>

            <FontAwesomeIcon icon="fa-brands fa-linkedin" />
            </a>

            </div>
            {/* <div className='col-lg-1 col-1'>
            <a  className="nav-link scrollto dropdown-active" href=''>

            <FontAwesomeIcon icon="fa-brands fa-facebook" />
            </a>

            </div> */}
            </div>
          </div>
          <div className="col-lg-2 col-12 mt-3">
          <h6><b>Contact Us</b></h6>
          <div className='row'>
          <div className='col-lg-1 col-1'>
          {/* <FontAwesomeIcon icon="fa-regular fa-envelope" /> */}
          <a href='mailto:ontact@kclub.me' style={{color: "white"}}>contact@kclub.me</a>
                   </div>
            {/* <div className='col-1 col-lg-1'> */}
           {/* <Env /> */}
           {/* <img src={Env} color={"white"} alt="" /> */}
            {/* </div> */}
          </div>
          </div>
          <div className="col-lg-5 col-12 mt-3">
          <h6><b>Links</b></h6>
          <div className='row'>
          <div className='col-lg-2 col-2'>
            <a  className="nav-link scrollto dropdown-active"
                    href="/#hero">
            About 
            </a>
            </div>
            |
            <div className='col-lg-2 col-3'>
            <a  className="nav-link scrollto dropdown-active"
                    href="/#features">Features</a>
            </div>    
            |      
            <div className='col-lg-3 col-4'>
            <a className="nav-link scrollto dropdown-active" href='/privacy-policy'>
           
            Privacy Policy
            </a>
            </div>
            |
            <div className='col-lg-4'>
            <a className="nav-link scrollto dropdown-active" href='/terms-and-conditions'>Terms & Conditions</a>
            </div>           
          </div>
          </div>
          <div className="col-lg-3 col-12 mt-3">
          <h6><b>Get the App</b></h6>
          {/* <div className="mb-2 mt-4"> */}
                {/* <p className="m-1">Get the App</p> */}
                <img
                  src={playstore}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                />{" "}
                &nbsp; &nbsp;
                <img
                  src={appstore}
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsOpen(true)}
                />
              {/* </div>  */}
          </div>
          </div>
         
        
        </div>
        <div className='text-center'>
          <div className="row mt-3" style={{justifyContent: "space-between"}}>  
          <div className="col-lg-12 col-12">
              © 2023 KClub. All rights reserved
            </div>
          {/* <div className="col-lg-12 col-12">
              Privacy Policy |  Terms and Conditions
            </div> */}
           
          </div>
          </div>
      </section> 
      </main>
  )
}
